<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="closeModal()"></adxad-modal-close-btn>
  <adxad-modal-header>
    <h2 adxadModalTitle>Change non-acceptable traffic</h2>
  </adxad-modal-header>

  <adxad-modal-content [formGroup]="form">
    <adxad-form-field class="field-group">
      <adxad-number-input
        formControlName="shavingRate"
        label="Non-acceptable traffic"
        [step]="step"
        [digits]="digits"
      ></adxad-number-input>
      <adxad-show-errors [control]="form.get('shavingRate')"></adxad-show-errors>
    </adxad-form-field>
  </adxad-modal-content>

  <adxad-modal-actions>
    <button
      adxadStrokedButton
      (click)="closeModal()"
    >
      {{ 'cancel' | transloco }}
    </button>

    <button
      adxadButton
      [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
      (click)="submit()"
    >
      <adxad-loader
        class="submit-loader"
        [diameter]="20"
        *ngIf="isLoadingSubmit"
      ></adxad-loader>
      <span [ngClass]="{ loading: isLoadingSubmit }"> {{ 'save' | transloco }} </span>
    </button>
  </adxad-modal-actions>
</ng-container>
