import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SpotService } from '../../../spots/spot.service';
import { RolesService } from '../../../../core/services/roles.service';
import { MessageService } from '../../../../core/services/message.service';
import { SspService } from '../../../../core/services/ssp.service';
import { TariffsService } from '../../tariffs.service';
import { forkJoin } from 'rxjs';
import { Dicts, SpotTariffCalendar, Tariff, TariffCalendarDate } from '../../../../core/interfaces';
import { takeUntil } from 'rxjs/operators';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

dayjs.extend(objectSupport);

@Component({
  selector: 'adxad-tariff-calendar',
  templateUrl: './tariff-calendar.component.html',
  styleUrls: ['./tariff-calendar.component.scss']
})
export class TariffCalendarComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public stage = 1;
  public data: any;

  public maxDate = new Date();
  public minDate = dayjs().subtract(65, 'days').toString();

  /** set tariff form */
  public isSetTariffOpen = false;
  public isLoadingSubmit = false;
  public tariff: SpotTariffCalendar;
  public selectedModel;
  public tariffData: Tariff;
  public isPerGeo = false;
  public isRevshare = false;

  public currentDate = dayjs();
  public dayNames = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  public dates: TariffCalendarDate[] = [];
  public months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  public years = [];
  public showCalendarStage = 'days';
  public dicts = {
    countries: {
      data: [{ id: 'default', value: 'Default' }]
    }
  } as Dicts;

  /** model */
  private models = {
    fixAllGeos: {
      id: 'fix_all_geo',
      title: 'All geos',
      description: 'One price for all countries'
    },
    fixPerGeo: {
      id: 'fix_per_geo',
      title: 'Per geo',
      description: 'Custom prices for each country'
    },
    revshare: {
      id: 'revshare',
      title: 'Revenue',
      description: 'Percent payment for conversions'
    },
    fixPerPeriod: {
      id: 'fix_per_period',
      title: 'Per period',
      description: 'Fixed price for period'
    }
  };
  private selectedDate = dayjs();
  private searchValue = '';
  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private alerts: AlertsComponent,
    private spotService: SpotService,
    private fb: UntypedFormBuilder,
    public roles: RolesService,
    private messageService: MessageService,
    private sspService: SspService,
    private tariffService: TariffsService
  ) {}

  ngOnInit(): void {
    if (this.config.data && this.config.data.id) {
      this.data = this.config.data;
    }

    if (dayjs(this.data.createdAt).isAfter(this.minDate)) {
      this.minDate = dayjs(this.data.createdAt).toString();
    }

    this.createForm();
    this.loadData();
    this.generateYears();
    this.generateCalendar();
  }

  createForm() {
    this.form = this.fb.group({
      ids: [[this.data.id]],
      newTariff: ['', Validators.required]
    });

    if (this.roles.isAdmin()) {
      this.form.addControl('dateStart', this.fb.control('', Validators.required));
      this.form.addControl('dateEnd', this.fb.control(''));
    }
  }

  submit() {
    if (this.isLoadingSubmit || !this.form.valid) {
      return;
    }
    this.isLoadingSubmit = true;

    const data = this.form.value;

    if (this.roles.isAdmin()) {
      data.dateStart = dayjs(data.dateStart).format('YYYY-MM-DD');
      data.dateEnd = dayjs(data.dateEnd).isValid() ? dayjs(data.dateEnd).format('YYYY-MM-DD') : null;
    }

    this.spotService
      .multiSetTariff(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingSubmit = false;
          if (result.status === 'OK') {
            this.alerts.success('Tariff set', 3000);
            this.messageService.add('reload-spot-grid', { submit: true });
            this.isSetTariffOpen = false;
            this.loadCalendar();
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }

  loadData(): void {
    const request = { limit: 300, page: 1 };
    const forkList = [this.sspService.getCountries(request)];

    if (!this.roles.isPublisher()) {
      forkList.push(this.sspService.getTariffs(request));
    }

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (results: any) => {
          this.dicts.countries.data.push(...results[0].data);

          if (!this.roles.isPublisher()) {
            this.dicts.tariffs = results[1];
          }
        }
      });
  }

  loadCalendar(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      id: this.data.id,
      from: this.dates[0].date.format('YYYY-MM-DD'),
      to: this.dates[this.dates.length - 1].date.format('YYYY-MM-DD')
    };
    this.spotService
      .getSpotTariffs(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          if (result.status === 'OK') {
            this.isLoading = false;
            this.setTariffs(result.data);

            if (this.selectedDate) {
              const selectedDate = this.dates.find(date => date.date.isSame(this.selectedDate, 'days'));

              if (selectedDate) {
                this.selectDate(selectedDate);
              }
            }
          }
        },
        error: () => (this.isLoading = false)
      });
  }

  openTariffInfo(): void {
    if (this.roles.isPublisher()) {
      return;
    }

    this.loadTariffData();
    this.changeStage(2);
  }

  loadTariffData(): void {
    if (this.tariffData && this.tariffData.id === this.tariff.tariff.id) {
      return;
    }

    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    this.tariffService
      .getTariffForm({ id: this.tariff.tariff.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.isLoading = false;
          this.tariffData = result.data;
          this.setRates(result.data.model);
        },
        error: () => (this.isLoading = false)
      });
  }

  setRates(value): void {
    this.isPerGeo = value === 'fix_per_geo';
    this.isRevshare = value === 'revshare';
  }

  setTariffs(tariffs: SpotTariffCalendar[]): void {
    tariffs.forEach(tariff => {
      const firstDate = dayjs(tariff.dateStart);
      const lastDate = dayjs(tariff.dateEnd);

      this.dates.forEach(day => {
        if (day.date.diff(firstDate, 'days', false) >= 0 && day.date.diff(lastDate, 'days', true) <= 0) {
          day.border = {
            left: day.date.day() === 1 || dayjs(day.date).isSame(firstDate, 'days'),
            right: day.date.day() === 0 || dayjs(day.date).isSame(lastDate, 'days')
          };

          day.color = tariff.tariff.model;
          day.tariff = tariff;
        }
      });
    });
  }

  getClassName(day: TariffCalendarDate): string {
    let className = '';

    if (day.today) {
      className += ' today';
    }
    if (day.selected) {
      className += ' selected';
    }
    if (day.isDisabled) {
      className += ' disabled';
    }
    if (day.color) {
      className += ' ' + day.color;
    }

    if (day.border && day.border.left) {
      className += ' calendar__date_border-left';
    }

    if (day.border && day.border.right) {
      className += ' calendar__date_border-right';
    }

    return className;
  }

  generateYears(firstYear?): void {
    const currentYear = this.currentDate.year();
    if (firstYear === undefined) {
      firstYear = currentYear - (currentYear % 4);
    }

    this.years = Array.from(Array(24)).map((e, i) => {
      const year = firstYear + i;
      return {
        currentYear: currentYear === year,
        value: year
      };
    });
  }

  generateCalendar(): void {
    const firstOfMonth = dayjs(this.currentDate).startOf('month').subtract(1, 'days').day();
    const firstDayOfGrid = dayjs(this.currentDate).startOf('month').subtract(firstOfMonth, 'days');
    const start = firstDayOfGrid.date();

    /** create list of days */
    this.dates = Array.from(Array(42)).map((e, i) => {
      const d = dayjs(firstDayOfGrid).date(start + i);
      const createdAt = dayjs(this.data.createdAt, 'D/M/YYYY H:mm:ss').valueOf();

      return {
        today: dayjs().isSame(dayjs(d), 'day'),
        isDisabled: dayjs(createdAt).diff(d, 'days') > 0,
        date: d
      };
    });
    this.loadCalendar();
  }

  /**
   * @param {string} stage - days, months or years
   */
  toggleStage(stage: string): void {
    this.showCalendarStage = this.showCalendarStage !== stage ? stage : 'days';
  }

  /** actions from calendar */
  prev(): void {
    if (this.showCalendarStage === 'days') {
      /** flip month */
      this.currentDate = dayjs(this.currentDate).subtract(1, 'months');
      this.generateCalendar();
    }

    if (this.showCalendarStage === 'months') {
      /** flip year */
      this.currentDate = dayjs(this.currentDate).subtract(1, 'year');
    }

    if (this.showCalendarStage === 'years') {
      /** flip 24 years */
      const year = this.years[0].value - 24;
      this.generateYears(year);
    }
  }

  next(): void {
    if (this.showCalendarStage === 'days') {
      /** flip month */
      this.currentDate = dayjs(this.currentDate).add(1, 'months');
      this.generateCalendar();
    }

    if (this.showCalendarStage === 'months') {
      /** flip year */
      this.currentDate = dayjs(this.currentDate).add(1, 'year');
    }

    if (this.showCalendarStage === 'years') {
      /** flip 24 years */
      const year = this.years[this.years.length - 1].value + 1;
      this.generateYears(year);
    }
  }

  selectMonth(month): void {
    this.currentDate = dayjs(this.currentDate).month(month);
    this.generateCalendar();
    this.showCalendarStage = 'days';
  }

  selectYear(year): void {
    this.currentDate = dayjs(this.currentDate).year(year);
    this.generateCalendar();
    this.showCalendarStage = 'months';
  }

  selectDate(date): void {
    /** remove before selected */
    const selectedDate = this.dates.find(x => x.selected);

    if (selectedDate) {
      selectedDate.selected = false;
    }

    this.selectedDate = date.mDate;

    date.selected = true;

    if (this.isSetTariffOpen) {
      this.isSetTariffOpen = false;
    }

    this.selectedModel = Object.values(this.models).find(x => x.id === date.tariff?.tariff.model);
    this.tariff = date.tariff;
  }

  /**
   * Change view on tariff form
   */
  openForm(): void {
    this.clearForm();
    this.isSetTariffOpen = true;
  }

  /**
   * Clear form
   */
  clearForm(): void {
    this.form.patchValue({
      newTariff: '',
      dateStart: '',
      dateEnd: ''
    });
  }

  changeStage(stage) {
    this.stage = stage;
  }

  /**
   * Return country name from dict
   * @param id
   */
  getCountry(id) {
    return this.dicts.countries.data.find(x => x.id === id).value;
  }

  /**
   * Load filtered tariffs dict
   *
   * @param {string} value
   */
  filterTariffs(value: string): void {
    const dict = this.dicts.tariffs;

    if (dict.isLoading) {
      return;
    }

    this.searchValue = value;

    dict.isLoading = true;
    dict.data = [];

    const request = {
      limit: 200,
      page: 1,
      search: value,
      status: ['active']
    };

    this.sspService
      .getTariffs(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;
          dict.data = dict.data.concat(result.data);
          dict.isLazy = result.meta ? result.meta.total > result.data.length : false;

          if (result.meta) {
            dict.meta = result.meta;
          }
        },
        error: () => (dict.isLoading = false)
      });
  }

  /**
   * Load tariffs lazy load dict by scroll
   */
  loadTariffs(): void {
    const item = this.dicts.tariffs;

    if (item.isLoading) {
      return;
    }

    item.isLoading = true;

    const request: any = {
      limit: 200,
      page: item.isLazy && item.meta ? item.meta.current_page + 1 : 1,
      status: ['active']
    };

    if (this.searchValue) {
      request.search = this.searchValue;
    }

    this.sspService
      .getTariffs(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          item.isLoading = false;

          item.data = item.data.concat(result.data);

          if (result.meta) {
            item.meta = result.meta;
            item.isLazy = item.meta.total > item.data.length;
          }
        },
        error: () => (item.isLoading = false)
      });
  }

  /**
   * Close modal
   */
  close(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
