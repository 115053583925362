import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { takeUntil } from 'rxjs/operators';
import { SpotService } from '../../spot.service';
import { MessageService } from '../../../../core/services/message.service';
import { AdxadValidators } from '../../../../core/validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-mass-shaving-rate',
  templateUrl: './mass-shaving-rate.component.html',
  styleUrls: ['./mass-shaving-rate.component.scss']
})
export class MassShavingRateComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public step = 1;
  public digits = 0;

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private modalRef: AdxadModalRef,
    public config: AdxadModalConfig,
    private alerts: AlertsComponent,
    private spotService: SpotService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    if (!this.config || !this.config.data) {
      this.closeModal();
    }
    this.createForm();
  }

  /**
   * Create form
   */
  createForm(): void {
    this.form = this.fb.group({
      ids: [this.config.data.ids],
      shavingRate: [10, [Validators.required, Validators.min(0), Validators.max(100), AdxadValidators.onlyIntegersAllowed]]
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }

  /**
   * Submit form
   */
  submit() {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }

    this.isLoadingSubmit = true;

    this.spotService
      .multiSetShavingRate(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingSubmit = false;
          if (result.status === 'OK') {
            this.messageService.add('reload-spots-grid', { submit: true });
            this.alerts.success('Set success', 3000);
            this.modalRef.close({ submit: true });
          }
        },
        error: () => (this.isLoadingSubmit = false)
      });
  }
}
