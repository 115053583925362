<adxad-loader
  adxadModalLoader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<ng-container *ngIf="!isLoading">
  <adxad-modal-close-btn (click)="close()"></adxad-modal-close-btn>

  <adxad-modal-header>
    <h2 adxadModalTitle>
      <ng-container
        [ngTemplateOutlet]="
          isSetTariffOpen ? setTariff : stages
        "
      ></ng-container>

      <ng-template #setTariff>
        <span
          class="material-icons pointer"
          (click)="isSetTariffOpen = false"
        >
          arrow_back_ios
        </span>
        Set tariff
      </ng-template>

      <ng-template #stages>
        <span
          class="material-icons pointer"
          *ngIf="stage === 2"
          (click)="changeStage(1)"
        >
          arrow_back_ios
        </span>
        {{ stage === 1 ? data.name + ' tariff' : 'View tariff details' }}
      </ng-template>
    </h2>
  </adxad-modal-header>

  <adxad-modal-content>
    <ng-container *ngIf="stage === 1 && !isSetTariffOpen">
      <p class="notice">Click on the day to see the tariff</p>

      <div class="calendar">
        <div class="calendar__header">
          <i
            class="material-icons"
            (click)="prev()"
          >
            keyboard_arrow_left
          </i>
          <div
            (click)="toggleStage('months')"
            class="calendar__header-selector"
          >
            {{ currentDate.format('MMMM') }}
          </div>
          <div
            (click)="toggleStage('years')"
            class="calendar__header-selector"
          >
            {{ currentDate.format('YYYY') }}
          </div>
          <i
            class="material-icons"
            (click)="next()"
          >
            keyboard_arrow_right
          </i>
        </div>

        <div class="calendar__body">
          <ng-container *ngIf="showCalendarStage === 'days'">
            <div class="calendar__day-names">
              <div
                *ngFor="let name of dayNames"
                class="calendar__day-name"
              >
                {{ name }}
              </div>
            </div>

            <div class="calendar__dates">
              <div
                *ngFor="let day of dates; let i = index"
                class="calendar__date"
                [ngClass]="getClassName(day)"
                (click)="selectDate(day)"
              >
                {{ day.date | date: 'dd' }}
              </div>
            </div>
          </ng-container>

          <div
            class="calendar__months-list"
            [ngClass]="{ open: showCalendarStage === 'months' }"
          >
            <div
              *ngFor="let month of months; let i = index"
              class="calendar__months-list-item"
              (click)="selectMonth(i)"
            >
              {{ month }}
            </div>
          </div>

          <div
            class="calendar__years-list"
            [ngClass]="{ open: showCalendarStage === 'years' }"
          >
            <div
              *ngFor="let year of years"
              class="calendar__years-list-item"
              (click)="selectYear(year.value)"
              [ngClass]="{ 'current-year': year.currentYear }"
            >
              {{ year.value }}
            </div>
          </div>
        </div>

        <div class="calendar__footer">
          <div
            class="current-tariff"
            *ngIf="tariff"
          >
            <div class="current-tariff__value">
              {{ tariff.tariff.name }}

              <div class="start-end">{{ tariff.dateStart | date }} - {{ tariff.dateEnd | date }}</div>
            </div>

            <div
              class="current-tariff__model"
              *ngIf="selectedModel"
            >
              <img
                class="current-tariff__model-icon"
                src="/assets/images/svg/{{ selectedModel.id }}.svg"
                alt="{{ selectedModel.title }}"
              />

              <div class="current-tariff__model-title">
                {{ selectedModel.title }}

                <div class="current-tariff__model-description">{{ selectedModel.description }}</div>
              </div>
            </div>
          </div>

          <div class="calendar__footer-actions">
            <button
              adxadButton
              (click)="openForm()"
              *ngIf="!roles.isPublisher()"
            >
              Set tariff
            </button>

            <button
              adxadStrokedButton
              (click)="openTariffInfo()"
              *ngIf="!roles.isPublisher()"
            >
              View tariff details
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <form
      [formGroup]="form"
      *ngIf="isSetTariffOpen"
    >
      <adxad-form-field class="field-group">
        <adxad-select
          placeholder="Select tariff"
          formControlName="newTariff"
          label="Tariff's"
          [options]="dicts.tariffs.data"
          [filterable]="true"
          (selectOption)="filterTariffs('')"
          (filterValue)="filterTariffs($event)"
          (scrollOnBottom)="loadTariffs()"
          [lazy]="dicts.tariffs.isLazy"
        ></adxad-select>

        <adxad-show-errors [control]="form.get('newTariff')"></adxad-show-errors>
      </adxad-form-field>

      <div
        class="row"
        *ngIf="roles.isAdmin()"
      >
        <adxad-form-field class="field-group col-6">
          <adxad-date-picker
            label="{{ 'startDate' | transloco }}"
            placeholder="Choose date"
            formControlName="dateStart"
            [min]="minDate"
            [max]="maxDate"
          ></adxad-date-picker>
          <adxad-show-errors [control]="form.get('dateStart')"></adxad-show-errors>
        </adxad-form-field>

        <adxad-form-field class="field-group col-6">
          <adxad-date-picker
            label="{{ 'endDate' | transloco }}"
            formControlName="dateEnd"
            placeholder="Choose date"
          ></adxad-date-picker>
          <adxad-show-errors [control]="form.get('dateEnd')"></adxad-show-errors>
        </adxad-form-field>
      </div>

      <div class="row set-tariff-actions">
        <div class="col">
          <button
            adxadStrokedButton
            (click)="isSetTariffOpen = !isSetTariffOpen"
            class="w100"
          >
            Cancel
          </button>
        </div>

        <div class="col">
          <button
            adxadButton
            [disabled]="!form.valid || !form.dirty || isLoadingSubmit"
            (click)="submit()"
            class="w100"
          >
            <adxad-loader
              class="submit-loader"
              [diameter]="20"
              *ngIf="isLoadingSubmit"
            ></adxad-loader>
            <span [ngClass]="{ loading: isLoadingSubmit }"> Save </span>
          </button>
        </div>
      </div>
    </form>

    <ng-container *ngIf="stage === 2 && !roles.isPublisher()">
      <div class="current-tariff">
        <div class="current-tariff__value">
          {{ tariff.tariff.name }}

          <div class="start-end">{{ tariff.dateStart | date }} - {{ tariff.dateEnd | date }}</div>
        </div>

        <div
          class="current-tariff__model"
          *ngIf="selectedModel"
        >
          <img
            class="current-tariff__model-icon"
            src="/assets/images/svg/{{ selectedModel.id }}.svg"
            alt="{{ selectedModel.title }}"
          />

          <div class="current-tariff__model-title">
            {{ selectedModel.title }}
            <div class="current-tariff__model-description">{{ selectedModel.description }}</div>
          </div>
        </div>
      </div>

      <section class="current-tariff__rates">
        <div class="row current-tariff__rates-header">
          <div
            class="col-3 --country"
            *ngIf="isPerGeo"
          >
            Geo
          </div>

          <div class="{{ isPerGeo ? 'col-3' : 'col-4' }}">
            <span class="material-icons">desktop_mac</span>
            Desktop {{ isRevshare ? '%' : '$' }}
          </div>
          <div class="{{ isPerGeo ? 'col-3' : 'col-4' }}">
            <span class="material-icons">tablet_mac</span>
            Tablet {{ isRevshare ? '%' : '$' }}
          </div>
          <div class="{{ isPerGeo ? 'col-3' : 'col-4' }}">
            <span class="material-icons">phone_iphone</span>
            Mobile {{ isRevshare ? '%' : '$' }}
          </div>
        </div>

        <div
          class="row current-tariff__rates-grid"
          *ngFor="let rate of tariffData.rates"
        >
          <div
            class="col-3 --country"
            *ngIf="isPerGeo"
          >
            {{ getCountry(rate['geo']) }}
          </div>
          <div class="{{ isPerGeo ? 'col-3' : 'col-4' }}">{{ rate['desktop'] || '0' }}</div>
          <div class="{{ isPerGeo ? 'col-3' : 'col-4' }}">{{ rate['tablet'] || '0' }}</div>
          <div class="{{ isPerGeo ? 'col-3' : 'col-4' }}">{{ rate['mobile'] || '0' }}</div>
        </div>
      </section>
    </ng-container>
  </adxad-modal-content>
</ng-container>
